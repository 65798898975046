import {
    fetchService,
    METHOD,
    tokenFetchService,
} from "../matchService/fetchService";
import { IP, URL } from "./IP";

async function obtenerImagenesCarrusel() {
    const response = await fetchService(
        IP(URL.LISTAR_CARRUSEL),
        null,
        METHOD.GET
    );
    return response;
}
async function crudImagenCarrusel(body) {
    const response = await tokenFetchService(
        IP(URL.CRUD_IMAGEN_CARRUSEL),
        body,
        METHOD.POST
    );
    return response;
}

async function eliminarImagenCarrusel(body) {
    const response = await tokenFetchService(
        IP(URL.DETELE_IMAGEN_CARRUSEL),
        body,
        METHOD.POST
    );
    return response;
}
export {
    crudImagenCarrusel,
    obtenerImagenesCarrusel,
    eliminarImagenCarrusel
}