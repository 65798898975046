import {
    fetchService,
    METHOD,
} from "../matchService/fetchService";
import { IP, URL } from "./IP";

async function obtenerMenus() {
    const response = await fetchService(
        IP(URL.LISTAR_MENU),
        null,
        METHOD.GET
    );
    return response;
}

export {
    obtenerMenus
}