import React from "react";
import { Link, useLocation } from "react-router-dom";
export default   function SuccesUsuario() {     
    const location = useLocation();
    return (<>
        <div>
            <div className="usuario-success">
                <h4>Has creado una cuenta de cliente!</h4>
                <p>El usuario fue creado con éxito, en breve recibirá un correo de confirmación o iniciar sesión desde <Link to={{
              pathname: '/dashboard',
              state: { from: location.state?.from },
            }}>aquí</Link>.</p>
            </div>
        </div>
    </>)
}