import { useEffect, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
    CRUD,
    HttpStatus,
    SUCCESS_SERVER,
  } from "../../service/ENUM";
  import ServerException from "../../utils/serverException";
import { eliminarImagenCarrusel, crudImagenCarrusel, obtenerImagenesCarrusel } from "../../service/carrusel.service";

let actionType = {
    LIST_IMAGENES: "LIST_IMAGENES",
    SET_CARRUSEL: "SET_CARRUSEL"
  };
  const reducer = (state, action) => {
    switch (action.type) {
        case actionType.LIST_IMAGENES:      
          return {
            ...state,
            listaImagenes: action.listaImagenes,
          };
        case actionType.SET_CARRUSEL:
            return {
                ...state, 
                carrusel: action.carrusel
            }
        default:
          return state;
        }
    }

export default function Carrusel() {
    const [file1, setFile1] = useState("");
    const [file2, setFile2] = useState("");
    const inputFile1 = useRef();
    const inputFile2 = useRef();
    const [state, dispatch] = useReducer(reducer, {
        listaImagenes:[],
        carrusel: {
            numCodigoCarrusel:0,
            blobSrcImage:'',
            blobSrcImageMobile:'',
            objectFit:'',
            typeImage:'png',
            typeImageMobile:'png',
            nombreDesktop: '',
            nombreMobile: '',
            crud: CRUD.SELECT
        },
        server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_DEFAULT },
      }); 

    useEffect(() => {
       obtenerImagenes();
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    
    async function handleEnventReadFileDesktop(e) {
        let _carrusel = state.carrusel;
        console.log(_carrusel);
        setFile1("");

        let file = e.target.files[0];
        if (file !== undefined) {
            console.log(file.name)
            _carrusel.nombreDesktop = file.name
            .replace(".png", "")
            .replace(".PNG", "");

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = await function () {
                let _replace = "data:image/png;base64,";
                let filetext = reader.result.replace(_replace, "");
                _carrusel.blobSrcImage = filetext;
                _carrusel.crud = state.carrusel.numCodigoCarrusel === 0? CRUD.INSERT: CRUD.UPDATE
            };
        } else {
            _carrusel.blobSrcImage = "";
            _carrusel.nombreDesktop = "";
        }
        setTimeout(() => {
            dispatch({type: actionType.SET_CARRUSEL, carrusel: _carrusel})
          }, 2000);
          
    }
    async function handleEnventReadFileMobile(e) {
        let _carrusel = state.carrusel;
        setFile2("");

        let file = e.target.files[0];
        if (file !== undefined) {
            _carrusel.nombreMobile = file.name
            .replace(".png", "")
            .replace(".PNG", "");

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = await function () {
                let _replace = "data:image/png;base64,";
                let filetext = reader.result.replace(_replace, "");
                _carrusel.blobSrcImageMobile = filetext;
                _carrusel.crud = state.carrusel.numCodigoCarrusel === 0? CRUD.INSERT: CRUD.UPDATE
            };
        } else {
            _carrusel.blobSrcImageMobile = "";
            _carrusel.nombreMobile = "";
        }
        setTimeout(() => {
            dispatch({ type: actionType.SET_CARRUSEL,carrusel : _carrusel})
          }, 2000);
    }
    async function handleEventGuardarProducto(){

        if(state.carrusel.blobSrcImage === ''|| state.carrusel.blobSrcImageMobile === ''){
            alert('Debe existir una imagen de Desktop y mobile para guardar los cambios');
            return;
        }
        const rpt = await crudImagenCarrusel({
            numCodigoCarrusel: state.carrusel.numCodigoCarrusel,
            blobSrcImage:  state.carrusel.blobSrcImage,
            blobSrcImageMobile: state.carrusel.blobSrcImageMobile,
            typeImage: state.carrusel.typeImage,
            typeImageMobile: state.carrusel.typeImageMobile,
            nombreDesktop: state.carrusel.nombreDesktop,
            nombreMobile: state.carrusel.nombreMobile,
            objectFit: 'fill',
            crud:  state.carrusel.numCodigoCarrusel === 0? CRUD.INSERT.descripcion: CRUD.UPDATE.descripcion
        });
        if (rpt.status === HttpStatus.HttpStatus_OK) {
            const json = await rpt.json();

            if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_OK) {
                handleEventLimpiarProducto();
                obtenerImagenes();
            }
            if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_INFO) {
                dispatch({
                  type: actionType.ERROR,
                  server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_INFO },
                });
            }
        }else {
            dispatch({
                type: actionType.ERROR,
                server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_ERROR },
            });
        }
      
    }
    async function handleEventEliminarProducto(){
        const rpt = await eliminarImagenCarrusel({
            numCodigoCarrusel: state.carrusel.numCodigoCarrusel,
            crud: CRUD.DELETE.descripcion
        });
        if (rpt.status === HttpStatus.HttpStatus_OK) {
            const json = await rpt.json();
            if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_OK) {
                handleEventLimpiarProducto();
                obtenerImagenes();
            }
            if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_INFO) {
                dispatch({
                  type: actionType.ERROR,
                  server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_INFO },
                });
            }

        }else {
            dispatch({
                type: actionType.ERROR,
                server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_ERROR },
            });
        }
    }
    function handleEventSelectItem(carrusel){
        carrusel.blobSrcImage = carrusel.blobSrcImage.replace(/(\r\n|\n|\r)/gm,"");
        carrusel.blobSrcImageMobile = carrusel.blobSrcImageMobile.replace(/(\r\n|\n|\r)/gm,"");
        carrusel.crud = CRUD.UPDATE;
        dispatch({ type: actionType.SET_CARRUSEL, carrusel})
        
    }
    async function handleEventLimpiarProducto(){
        setFile1("");
        setFile2("");
        let  carrusel =  {
            numCodigoCarrusel:0,
            blobSrcImage:'',
            blobSrcImageMobile:'',
            objectFit:'',
            typeImage:'png',
            typeImageMobile:'png',
            nombreDesktop: '',
            nombreMobile: '',
            crud: CRUD.SELECT
        }
        dispatch({ type: actionType.SET_CARRUSEL, carrusel: carrusel });
    }
    async function obtenerImagenes(){
      const rpt = await obtenerImagenesCarrusel();
      if (rpt.status === HttpStatus.HttpStatus_OK) {
        const json = await rpt.json();
        if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_OK) {
           let listaImagenes =  json.lista.map((carrusel) => (
            <tr  className="prod-img-search-card" key={carrusel.numCodigoCarrusel}  >
                <td style={{ width: "5%", textAlign: "center" }} onClick={() => handleEventSelectItem(carrusel)}>
                <i style={{ color: "black"}} className="fa fa-pencil fa-1" aria-hidden="true"></i>
                </td>
                <td style={{ width: "20%", textAlign: "center" }}>
                <img src={"data:image/png;base64," + carrusel.blobSrcImage} alt={carrusel.nombreDesktop}></img>
                </td>
                <td style={{ width: "20%", textAlign: "center" }}>
                <img src={"data:image/png;base64," + carrusel.blobSrcImageMobile} alt={carrusel.nombreMobile}></img>
                </td>
            </tr>
           ));
           dispatch({ type: actionType.LIST_IMAGENES, listaImagenes}) 
        }
        if (json.response.status === SUCCESS_SERVER.SUCCES_SERVER_INFO) {
            dispatch({
              type: actionType.ERROR,
              server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_INFO },
            });
        }
    } else {
        dispatch({
          type: actionType.ERROR,
          server: { error: "", success: SUCCESS_SERVER.SUCCES_SERVER_ERROR },
        });
    }

}

return ( 
    <>
        <div className="link-href">
                <Link to="/dashboardAdmin">
                    <i className="fa fa-home" aria-hidden="true"></i>
                    Panel de Control
                </Link>
            </div>
            <h3>Mantenimiento Carrusel</h3>
            <div className="prod-img-upload">
                <div className="prod-img-search-det">
                <div className="prod-img-search-row">
                <table>
                    <thead>
                    <tr>
                        <td
                        style={{
                            width: "5%",
                            textAlign: "center",
                            fontWeight: "bold",
                            height: 45,
                        }}
                        >
                        Codigo
                        </td>
                        <td
                        style={{
                            width: "20%",
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                        >
                        Imagen Desktop
                        </td>
                        <td
                        style={{
                            width: "20%",
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                        >
                        Imagen Mobile
                        </td>
                    </tr>
                    </thead>
                    <tbody>{state.listaImagenes}</tbody>
                </table>
                </div>
                <div className="prod-img-search-row2">
                <div className="producto-card">
                    <div className="prod-card-row prod-card-row-center">
                    <input
                        type="file"
                        accept=".png"
                        className="form-ctrl form-ctrl-lg"
                        value={file1}
                        onChange={handleEnventReadFileDesktop}
                        ref={inputFile1}
                    ></input>
                    <button
                        className="btn btn-primary fa fa-upload btn-file"
                        onClick={(e) => {
                        inputFile1.current && inputFile1.current.click();
                        }}
                    >
                        &nbsp;Seleccionar Imagen Desktop
                    </button>
                    <input
                        type="file"
                        accept=".png"
                        className="form-ctrl form-ctrl-lg"
                        value={file2}
                        onChange={handleEnventReadFileMobile}
                        ref={inputFile2}
                    ></input>
                    <button
                        className="btn btn-primary fa fa-upload btn-file"
                        onClick={(e) => {
                            inputFile2.current && inputFile2.current.click();
                        }}
                    >
                        &nbsp;Seleccionar Imagen Mobile
                    </button>
     
                    </div>
                    <div className="prod-card-row">
                    <label>Código</label>
                    <div>{state.carrusel.numCodigoCarrusel}</div>
                    <label>Tipo</label>
                    <div>{state.carrusel.typeImage}</div>
                    </div>

                    <div className="prod-card-row">
                    <label>Nombre</label>
                    <div>{state.carrusel.nombreDesktop}</div>
                    <label>Nombre</label>
                    <div>{state.carrusel.nombreMobile}</div>
                    </div>
                    <div className="prod-card-row row-img row-center">
                    {state.carrusel.blobSrcImage === "" ? (
                        ""
                    ) : (
                        <img
                        src={
                            "data:image/png;base64," +
                            state.carrusel.blobSrcImage
                        }
                        alt={state.carrusel.numCodigoCarrusel}
                        ></img>
                    )}
                       {state.carrusel.blobSrcImageMobile === "" ? (
                        ""
                    ) : (
                        <img
                        src={
                            "data:image/png;base64," +
                            state.carrusel.blobSrcImageMobile
                        }
                        alt={state.carrusel.numCodigoCarrusel}
                        ></img>
                    )}
                    </div>
                    <hr />
                    <div className="prod-card-row row-center">
                    <button
                        className="btn btn-primary  fa fa-save"
                        title="Editar"
                        onClick={handleEventGuardarProducto}
                        disabled={
                        state.carrusel.crud.codigoCrud ===
                            CRUD.INSERT.codigoCrud ||
                        state.carrusel.crud.codigoCrud ===
                            CRUD.UPDATE.codigoCrud
                            ? false
                            : true
                        }
                    >
                        &nbsp;Guardar
                    </button>
                    <button
                        className="btn btn-primary  fa fa-trash"
                        title="Eliminar"
                        onClick={handleEventEliminarProducto}
                        disabled={
                        state.carrusel.crud.codigoCrud ===
                            CRUD.DELETE.codigoCrud ||
                        state.carrusel.crud.codigoCrud ===
                            CRUD.UPDATE.codigoCrud
                            ? false
                            : true
                        }
                    >
                        &nbsp;Eliminar
                    </button>
                    <button
                        className="btn btn-primary  fa fa-eraser"
                        title="Limpiar"
                        onClick={handleEventLimpiarProducto}
                    >
                        &nbsp;Limpiar
                    </button>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <ServerException server={state.server}></ServerException>
    </>
    );
}